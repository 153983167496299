import { useMemo } from 'react';
import { useParams, useLocation } from 'react-router';
import Context from 'casino-context';
import { GameLaunch } from 'CasinoV2/lazy_components';

const App = () => {
  const params = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const parentSessionId = queryParams.get('parentSessionId');
  const gameSessionId = queryParams.get('game_session_id');
  const isAppWebView = queryParams.get('app_web_view') === 'true';

  const contextValue = useMemo(
    () => ({
      embedded: isAppWebView ? false : 'pp-bingo-minigame',
      ownOverlay: true,
      hideHeader: true,
      isAppWebView,
      parentSessionId,
      gameSessionId,
    }),
    [isAppWebView, parentSessionId, gameSessionId],
  );

  return (
    <Context.Provider value={contextValue}>
      <div className="mini-launch">
        <GameLaunch match={{ params }} location={location} />
      </div>
    </Context.Provider>
  );
};

export default App;
